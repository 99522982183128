:root {
  --bs-primary: #25cfc4;
  --bs-btn-bg: #25cfc4;
}

body {
  background: #fafafa;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.indented {
  text-indent: 20px;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #848484 !important;
}

* {
  box-sizing: border-box;
}


/* Table */

.table-dark {
  --bs-table-color: #2C2C2C !important;
  --bs-table-bg: #CBCBCB !important;
  --bs-table-border-color: #CBCBCB !important;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table.chart-table {
  --bs-table-bg: #F3F3F3 !important;
}

.table.main-background{
  --bs-table-bg: #fafafa !important;
}

.table.inherit-color {
  --bs-table-color: inherit !important;
}

table tbody tr:last-child {
  border-bottom: #F3F3F3;
}

.bdr {
  border-radius: 8px;
  overflow: hidden;
}

.arrow {
  margin-left: 5px;
}

.up {
  transform: rotate(180deg);
}

.alert-row td {
  background-color: #FAD6D4 !important;
}

.orange-row td {
  background-color: #FDE7D3 !important;
}

.table td {
  word-break: break-word; /* Break long words */
  white-space: pre-wrap; /* Preserve whitespace and wrap text */
  max-width: 200px; /* Adjust the width as needed */
  overflow-wrap: anywhere; /* Break long words anywhere if needed */
}

.table .message-cell {
  max-width: 200px; /* Set maximum width for message cells */
}

.last-element td {
  border-bottom: 1px solid #CBCBCB; /* Adjust the color and thickness as needed */
}

/* Button */

button:focus:not(.btn.btn-primary):not(.btn.mqtt-button) {
  box-shadow: 0 0 0 0.25rem rgba(37, 207, 196, 0.25);
}

button:active {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}

.btn.btn-primary:hover {
  text-decoration: none;
  background-color: rgb(25, 144, 137) !important;
  border-color: rgb(25, 144, 137) !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.btn.btn-primary:focus-visible {
  background-color: #6fede5 !important;
  border-color: #6fede5 !important;
}

.btn:focus {
  box-shadow: 0 0 0 0rem rgba(37, 207, 196, 0.25);
}

.btn.btn-primary {
  color: #fff;
  --bs-btn-bg: #25cfc4 !important;
  --bs-btn-border-color: #25cfc4 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #25cfc4 !important;
  --bs-btn-hover-border-color: #25cfc4 !important;
  --bs-btn-active-bg: #25cfc4 !important;
  --bs-btn-active-border-color: #25cfc4 !important;
  --bs-btn-border-radius: 2rem !important;
  --bs-btn-padding-x: 6rem !important;
  --bs-btn-padding-y: 1rem !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
}

.custom-primary-button {
  background-color: #25CFC4; 
  color: white;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.btn-lg {
  width: 400px !important;
}

.btn {
  padding-top: 0.05rem !important;
  padding-bottom: 0.05rem !important;
}

.btn-secondary {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  border-color: black !important;
}

.btn.btn-danger {
  background-color: rgba(255, 122, 122, 0.7);
}

.btn.btn-warning {
  background-color: rgba(255, 233, 88, 0.7);
}

.btn.fixed-button-left {
  position: fixed;
  bottom: 40px;
  right: 250px;
  --bs-btn-padding-x: 1.6rem !important;
  --bs-btn-padding-y: 0rem !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
  height: 3.7rem !important;
}

.btn.btn-primary.fixed-button {
  position: fixed;
  bottom: 60px;
  right: 60px;
  --bs-btn-padding-x: 1.6rem !important;
  --bs-btn-padding-y: 0rem !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
  height: 3.7rem !important;
}

.custom-size-span {
  font-size: 40px;
}

.mqtt-button {
  background-color: #fff;
  border: var(--bs-border-width) solid #CBCBCB;
  border-radius: 0.275rem;
  padding: 0.35rem 2.1rem 0.35rem 2.1rem !important;
}

.btn.mqtt-button:hover {
  background-color: #F3F3F3;
  border-color: #25CFC4;
}

.btn.mqtt-button:active {
  background-color: #fff;
  border-color: #25CFC4;
  box-shadow: 0 0 0 0.25rem rgba(37, 207, 196, 0.25);
}

.btn.mqtt-button:focus {
  border-color: #25CFC4;
}

.blue-link {
  font-weight: bold !important;
  color: #25CFC4 !important;
}

.button-container {
  position: relative;
  display: inline-block;
}

.hover-text {
  visibility: hidden;
  color: black; /* Set text color to black */
  text-align: center;
  position: absolute;
  width: 100%;
  top: 100%; /* Position the text below the button */
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px; /* Adjust as necessary for spacing */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
}

.button-container:hover .hover-text {
  visibility: visible;
  opacity: 1;
}


/* Multi Select */

.css-13cymwt-control {
  background-color: inherit !important;
  line-height: 1.7rem;
  min-height: 2.63rem !important;
  border: var(--bs-border-width) solid #CBCBCB;
  border-radius: 0.275rem !important;
}

.css-13cymwt-control:hover {
  border-color: #221E1E;
}

.css-t3ipsp-control {
  border: 3px solid #25cfc4 !important;
  box-shadow: 0 0 0 0rem rgba(37, 207, 196, 0.25) !important;
  min-height: 2.63rem;
}

.css-ck1t7b-control {
  border: 3px solid #25cfc4 !important;
  box-shadow: 0 0 0 0rem rgba(37, 207, 196, 0.25) !important;
}

.css-blhudp-control {
  border: 3px solid #25cfc4 !important;
  box-shadow: 0 0 0 0rem rgba(37, 207, 196, 0.25) !important;
  min-height: 2.63rem;
}

.css-v68sna-control {
  background-color: inherit !important;
  line-height: 1.7rem;
  min-height: 2.63rem !important;
  border: var(--bs-border-width) solid #CBCBCB;
  border-radius: 0.275rem;
}

.clear-background .css-13cymwt-control {
  background-color: #fff !important;
}

.clear-background .css-v68sna-control {
  background-color: #fff !important;
}

select[multiple] {
  appearance: initial !important;
}

.css-1p3m7a8-multiValue {
  background-color: #F3F3F3 !important;
}

.select__input-container {
  display: none !important; /* Hide the input container */
}

/* Modal */

.modal {
  --bs-border-radius-lg: 1.3rem !important;
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-body-bg: #F3F3F3 !important;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-footer-border-width: 0 !important;
  --bs-modal-header-border-width: 0 !important;
}

.modal.clear-background {
  --bs-modal-bg: rgb(255, 255, 255) !important;
}

.modal-backdrop {
  background-color: rgb(243,243,243/1);
}

.custom-backdrop {
  opacity: 1 !important;
  background: rgba(0, 0, 0, 1) !important;
}

.custom-backdrop-class {
  background-color: rgba(0, 0, 0, 0.5) !important;
}


/* Sidebar */

CDBSidebar {
  display: flex;
  height: 100% !important;
}

.offcanvas.offcanvas-start.show {
  width: 18rem;
}


/* Form inputs */

.form-control:not(textarea) {
  line-height: 1.7rem;
  border: var(--bs-border-width) solid #CBCBCB;
  background-color: #fff;
  height: 2.62rem;
}

textarea {
  border: var(--bs-border-width) solid #CBCBCB !important;
  background-color: #fff !important;
}

textarea:hover {
  border-color: #221E1E !important;
}

textarea:focus {
  border: 2px solid #25cfc4 !important;
  box-shadow: 0 0 0 0rem rgba(37, 207, 196, 0.25);
  background-color: #fff;
}

.form-control:hover {
  border-color: #221E1E;
}

.form-control:focus {
  border: 2px solid #25cfc4;
  box-shadow: 0 0 0 0rem rgba(37, 207, 196, 0.25);
  background-color: #fff;
}

.form-control:focus:not(textarea) {
  height: 2.56rem;
}

.form-control.dark-placeholder-color {
  --bs-secondary-color: var(--bs-body-color);
}

.css-1jqq78o-placeholder {
  color: #212529 !important;
}

.form-search {
  position: relative;
  display: inline-block;
}

.icon {
  position: absolute;
  left: 14px;
  top: 9px;
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23CBCBCB%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  border: var(--bs-border-width) solid #CBCBCB;
  border-radius: 0.275rem;
  line-height: 1.7rem;
  padding: 0.5rem 2.25rem 0.375rem 0.75rem;
  background-color: #fff;
}

.form-select:hover {
  border-color: #221E1E;
}

.form-select:focus {
  border-color: #25CFC4;
  border-width: 3px;
  box-shadow: 0 0 0 0rem rgba(37, 207, 196, 0.25);
  height: 2.65rem;
}

.form-control-muted {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.btn-muted {
  background-color: #d6d8db !important;
  border-color: #d6d8db !important;
  color: #6c757d !important;
  cursor: not-allowed !important;
}

/* Container */

.hide-container {
  display: none;
}

.content-container {
  margin-left: 320px;
}

.input-container {
  position: relative;
}

.input-container input {
  padding-left: 40px;
}


/* Charts */

.card {
  --bs-card-bg: #F3F3F3 !important;
  background-color: var(--bs-card-bg) !important;
  --bs-card-border-color: #FFFF !important;
  border-color: #fafafa !important;
  --bs-card-border-radius: 1.3rem !important;
  --bs-card-border-radius: var(--bs-card-border-radius) ;
  border-radius: var(--bs-card-border-radius) !important;
}


/* List Group (State Counter)*/

.custom-list-item {
  padding-top: 31px !important;
  padding-bottom: 12 !important;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  --bs-list-group-border-radius: 1.3rem !important;
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  --bs-list-group-border-radius: 1.3rem !important;
  border-top-left-radius: var(--bs-list-group-border-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


/* To order */
/* 
Table
Button
Multi select
Modal
Sidebar
Form inputs
Container
Charts
List Group (State Counter)
*/